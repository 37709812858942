$primary-color: #000000;
$secondary-color: #FDF7FA;
$accent-color-one: #E6C79C;
$accent-color-two: #ACFCD9;
$accent-color-three: #7D70BA;
$muted-color: #B7B6C2;
$m-c-1: #333533;

@mixin image-default-styling {
    background-color: $primary-color;
    border: 1px solid $muted-color;
    border-radius: 10px;
    margin: 5vh auto;
    max-width: 100%;
}

@mixin button-default-styling {
    align-items: center;
    background-color: $primary-color;
    border: 3px solid $muted-color;
    border-radius: 5px;
    color: set-text-color($primary-color);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: Orbitron, 'Courier New', Courier, monospace;
    letter-spacing: .2rem;
    margin: 1rem;
    padding: 1rem;
    text-align: center;
    @include transition-ease;

    small {
        padding: 1rem;
    }

    i {
        font-size: 2rem;
    }

    &:hover {
        opacity: 0.9;
    }
}

@mixin devIcons-default-styling {
    font-size: 3rem;
    margin: 5vh 0;
      
        .icon {
            margin: 0 .5vw;
      
        &-js {
            color: #f7df1e;
        }
        &-react {
            color: #7cc5d9;
        }
        &-html {
            color: #f06529;
        }
        &-css {
            color: #2965f1;
        }
        &-sass {
            color: #cd6799;
        }
        &-node {
            color: #68a063;
        }
        &-npm {
            color: #cc3534;
        }
        &-git {
            color: #f34f29;
        }
    }
}

@mixin transition-ease {
    transition: 0.6s ease-in-out;
}

@mixin transition-ease-delay-one {
    transition: all 0.6s ease-in-out 0.6s;    
}

@mixin transition-ease-delay-two {
    transition: all 0.8s ease-in-out 0.8s;    
}

@mixin transition-ease-delay-three {
    transition: all 1.0s ease-in-out 1.0s;    
}

@mixin transition-ease-delay-four {
    transition: all 1.2s ease-in-out 1.2s;
}

@mixin media-mobile {
    @media screen and (max-width: 750px) {
        @content;
    }
}

@mixin media-medium {
    @media screen and (min-width: 750px) and (max-width: 1330px) {
        @content;
    }
}

@mixin media-desktop {
    @media screen and (min-width: 1330px) {
        @content;
    }
}

@function set-text-color($color) {
    @if (lightness($color) > 40%) {
        @return $primary-color;
    } @else {
        @return $secondary-color;
    }
}