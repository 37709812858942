@import "../config";

.bg {
  position: fixed;
  
  .item {
    box-shadow: inset 0 0 0 5px rgba(255,255,255,0.1);
    background-position: center;
    background-size: cover;
    flex: 1;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;

    &.bg1 {
      background-image:
      linear-gradient(to right,
      rgba($primary-color, 0.1),
      rgba($primary-color, 0.3)),
      url('../images/self-desert-p1.jpg');
      background-size: cover;
      opacity: 1;
      

      &.open {
        opacity: 0;
        transform: translate(-100%);
        @include transition-ease-delay-one;
      }

    }

    &.bg2 {
      background-image:
      linear-gradient(to right,
      rgba($primary-color, 0.3),
      rgba($primary-color, 0.5)),
      url('../images/self-desert-p2.jpg');
      background-size: cover;
      transition: all 3s ease-in-out;

      .page {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        font-family: Orbitron, 'Courier New', Courier, monospace;
        height: 100%;
        justify-content: flex-start;
        opacity: 0;
        padding: 12vh 0 0 0;
        text-align: center;
        width: 100%;

        p {
          border-radius: 10px;
          font-size: 1.5rem;
          margin: 0;
          padding: 1.5rem;
          width: 6.5vw;
        }
      }

      &.open {
        opacity: 0.3;
        transform: translate(-100%);
        @include transition-ease-delay-two;

        .page {
          opacity: 1;
          @include transition-ease-delay-three;
        }
      }
    }

    &.bg3 {
      background-image:
      linear-gradient(to right,
      rgba($primary-color, 0.5),
      rgba($primary-color, 0.7)),
      url('../images/self-desert-p3.jpg');
      background-size: cover;

      &.open {
        opacity: 0.3;
        transform: translate(-100%);
        @include transition-ease-delay-three;
      }

    }

    &.bg4 {
      background-image:
      linear-gradient(to right,
      rgba($primary-color, 0.7),
      rgba($primary-color, 0.9)),
      url('../images/self-desert-p4.jpg');
      background-size: cover;

      &.open {
        opacity: 0.3;
        transform: translate(-100%);
        @include transition-ease-delay-four;
      }

    }

  }

}